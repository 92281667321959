import React from "react";
import { Icon } from "semantic-ui-react";
import styles from "./LandingPage/LandingForm/LandingForm.module.css";

export const PlatformMigrationPopup: React.FC = () => {
  return (
    <div style={{ maxWidth: "1000px" }}>
      <div className={styles.popupWarningIcon}>
        <div className={styles.iconCircle}>
          <div className={styles.iconInnerTriangle}></div>
          <div className={styles.iconOuterTriangle}></div>
          <Icon name="warning" className={styles.warningIcon} />
        </div>
      </div>
      <div className={styles.migrationDetailsContainer}>
        <h1>Important Message</h1>
        <h5>Regarding Your Account</h5>
      </div>
      <div className={styles.migrationDetailsBody}>
        <h5>Dear User,</h5>
        <p>
          To provide excellent service to all of our customers, we at Verimatrix
          are continuously improving our services to serve you better. The last
          improvement has been the migration of Verimatrix App Shield to our
          Verimatrix Platform. This brings all Verimatrix’s cloud-services into
          one single place; and you can even experience our new and trendy dark
          mode user interface.
          <br />
          <h5>
            Now, that the upgrade is completed, you may notice the following
            changes:
          </h5>
          <ul>
            <li>New, harmonized user interface of Verimatrix Platform</li>
            <li>Find all your Verimatrix subscriptions in one place</li>
            <li>
              Refreshed navigation to Dashboards, Apps and API keys for
              Developers
            </li>
            <li>
              Access to all the same functionality that you are already familiar
              with.
            </li>
          </ul>
          <p>
            If you have any questions or concerns, please email to
            <a href="#"> appshield@verimatrix.com.</a>
          </p>
        </p>
      </div>
    </div>
  );
};
